<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick" class="exportmalltab">
      <el-tab-pane label="订单支付" name="0">
        <div class="add">
          <el-select v-model="type" placeholder="请选择订单状态" style="width:150px;margin-right: 10px;" clearable @clear="type = ''" size="small">
            <el-option v-for="item in options1" :key="item.id" :label="item.value" :value="item.id">
            </el-option>
          </el-select>
          <el-date-picker size="small" v-model="date" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" clearable style="width:260px;margin-right: 10px;">
          </el-date-picker>
          <el-button @click="query(1)" type="primary" size="small">查询</el-button>
          <el-button type="primary" @click="exportorder" size="small">导出Excel</el-button>
        </div>
        <div v-if="shownum1" style="text-align:left;line-height:30px;">
          总计：{{ countnum1 }}个 本页共{{ totalnum1 }}元
        </div>
        <el-table :data="list" border style="width: 100%" id="tableBox" v-loading="loaddata" :element-loading-text="text">
          <el-table-column prop="orderId" label="订单编号" width="160"></el-table-column>
          <el-table-column prop="billNo" label="支付单号" width="180"></el-table-column>
          <el-table-column prop="receiver" label="收件人"></el-table-column>
          <el-table-column prop="tel" label="电话" width="120"></el-table-column>
          <el-table-column prop="address" label="收货地址" width="180"></el-table-column>
          <el-table-column prop="total" label="金额">
            <template slot-scope="scope"> ￥{{ scope.row.total }} </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
          <el-table-column prop="payTime" label="支付时间" width="160"></el-table-column>
          <el-table-column prop="payType" label="支付类型">
            <template slot-scope="scope">
              {{ paytype(scope.row.payType) }}
            </template>
          </el-table-column>
          <el-table-column v-if="istk" prop="refundTime" label="申请退款时间"></el-table-column>
          <el-table-column v-if="istk" prop="processTime" label="处理退款时间"></el-table-column>
        </el-table>
        <p class="paging">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value"></el-pagination>
        </p>
      </el-tab-pane>
      <!-- <el-tab-pane label="退保证金" name="1">
        <div class="add">
          <el-select v-model="type2" placeholder="请选择订单状态" style="width:150px;margin-right: 10px;" clearable @clear="type2 = ''" size="small">
            <el-option v-for="item in options2" :key="item.id" :label="item.value" :value="item.id">
            </el-option>
          </el-select>
          <el-date-picker size="small" v-model="date1" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" clearable style="width:260px;margin-right: 10px;">
          </el-date-picker>
          <el-button @click="query1(1)" type="primary" size="small">查询</el-button>
          <el-button type="primary" @click="exportorder" size="small">导出Excel</el-button>
        </div>
        <div v-if="shownum" style="text-align:left;line-height:30px;">
          总计：{{ countnum }}个 共{{ totalnum }}元
        </div>
        <el-table :data="tableData" border style="width: 100%" id="tableBox1" v-loading="loaddata" :element-loading-text="text">
          <el-table-column prop="auctionId" label="订单编号"></el-table-column>
          <el-table-column prop="billNo" label="支付单号"></el-table-column>
          <el-table-column prop="tel" label="电话"></el-table-column>
          <el-table-column prop="deposit" label="保证金">
            <template slot-scope="scope"> ￥{{ scope.row.deposit }} </template>
          </el-table-column>
          <el-table-column prop="goodsName" label="产品名"></el-table-column>
          <el-table-column prop="num" label="数量"></el-table-column>
          <el-table-column prop="price" label="金额">
            <template slot-scope="scope"> ￥{{ scope.row.price }} </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间"></el-table-column>
        </el-table>
        <p class="paging">
          <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="current1" :page-sizes="pageSizes1" :page-size="PageSize1" layout="total, sizes, prev, pager, next, jumper" :total="totalCount1" :hide-on-single-page="value1"></el-pagination>
        </p>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
const FileSaver = require("file-saver");
const XLSX = require("xlsx");
export default {
  data() {
    return {
      activeName: "0",
      type: "",
      date: [],
      date1: [],
      list: [],
      tab: "",
      options1: [
        { id: "0", value: "已支付订单" },
        { id: "1", value: "已退款订单" },
      ],
      type2: "",
      options2: [
        { id: "0", value: "未退保证金" },
        { id: "1", value: "已退保证金" },
      ],
      istk: false,
      tableData: [],
      paystate: [
        { id: 0, value: "微信支付" },
        { id: 1, value: "支付宝支付" },
        { id: 3, value: "易飞微信支付" },
        { id: 4, value: "易飞支付宝支付" },
      ],
      countnum: "",
      totalnum: "",
      countnum1: "",
      totalnum1: "",
      shownum: false,
      shownum1: false,
      loaddata: false,
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [50], // 个数选择器（可修改）
      PageSize: 50, // 默认每页显示的条数（可修改）
      value: false,
      current1: 1, //默认显示第几页
      totalCount1: 0, // 总条数，根据接口获取数据长度
      pageSizes1: [50], // 个数选择器（可修改）
      PageSize1: 50, // 默认每页显示的条数（可修改）
      value1: false,
      text: "",
    };
  },
  watch: {
    date(newDate, oldDate) {
      if (newDate == null) {
        this.date = [];
      }
    },
    date1(newDate, oldDate) {
      if (newDate == null) {
        this.date1 = [];
      }
    },
  },
  mounted() {},
  methods: {
    paytype(val) {
      var str = "";
      for (let a = 0; a < this.paystate.length; a++) {
        if (val == this.paystate[a].id) {
          str = this.paystate[a].value;
        }
      }
      return str;
    },
    handleClick(tab, event) {
      this.tab = tab.index;
      if (tab.index == 0) {
        this.countnum = "";
        this.totalnum = "";
        this.shownum = false;
      } else {
        this.countnum1 = "";
        this.totalnum1 = "";
        this.shownum1 = false;
      }
      this.type = "";
      this.type2 = "";
      this.date = [];
      this.list = [];
      this.tableData = [];
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.query(currentPage);
    },

    handleSizeChange1() {},
    handleCurrentChange1(currentPage) {
      this.query1(currentPage);
    },
    query(page) {
      this.loaddata = true;
      this.text = "数据加载中，请稍后...";
      var url = "",
        str = "";
      if (this.date.length > 0) {
        str += "?start=" + this.date[0];
        str += "&end=" + this.date[1];
      } else {
        this.$message({
          type: "error",
          message: "请选择时间",
        });
        return;
      }
      if (this.type == "") {
        this.$message({
          type: "error",
          message: "请选择状态",
        });
        return;
      } else {
        str += "&type=" + this.type;
      }
      str += "&index=" + page;
      url = "/api/admin/ProcessOrderEx/get_order_pay_report";
      this.axios.get(this.serveurl.mallserve + url + str).then((res) => {
        if (res.data.code == 1) {
          this.list = res.data.data.list;
          this.countnum1 = res.data.data.count;
          this.totalnum1 = res.data.data.total;
          this.shownum1 = true;
          this.loaddata = false;
          this.totalCount = res.data.data.count;
        }
      });
      if (this.type == 1) {
        this.istk = true;
      }
    },
    query1(page) {
      this.loaddata = true;
      this.text = "数据加载中，请稍后...";
      var url = "",
        str = "";
      if (this.date1.length > 0) {
        str += "?start=" + this.date1[0];
        str += "&end=" + this.date1[1];
      } else {
        this.$message({
          type: "error",
          message: "请选择时间",
        });
        return;
      }
      if (this.type2 == "") {
        this.$message({
          type: "error",
          message: "请选择状态",
        });
        return;
      } else {
        str += "&isRefund=" + this.type2;
      }
      str += "&index=" + page;
      url = "/api/admin/ProcessOrderEx/get_refund_deposit";
      this.axios.get(this.serveurl.mallserve + url + str).then((res) => {
        if (res.data.code == 1) {
          this.tableData = res.data.data.list;
          this.countnum = res.data.data.count;
          this.totalnum = res.data.data.total;
          this.shownum = true;
          this.loaddata = false;
          this.totalCount1 = res.data.data.count;
        }
      });
    },
    exportorder() {
      //判断时间，只能一天
      this.loaddata = true;
      this.text = "正在导出数据，请稍等...";
      var day1 = "",
        day2 = "";
      if (this.tab == 0) {
        // this.exportOut("#tableBox");
        if (this.type == "") {
          this.$message({
            type: "error",
            message: "请选择条件",
          });
          return;
        }
        if (this.date.length > 0) {
          day1 = new Date(this.date[0]);
          day2 = new Date(this.date[1]);
          if (day1.getTime() == day2.getTime()) {
            var dom = document.createElement("a");
            //下载文件的路径
            dom.href =
              this.serveurl.mallserve +
              "/api/admin/ProcessOrderEx/export_order_pay_report?start=" +
              this.date[0] +
              "&end=" +
              this.date[1] +
              "&type=" +
              this.type;
            //对下载的文件命名
            dom.download = '';
            dom.click();
            this.loaddata = false;
          } else {
            this.$message({
              type: "error",
              message: "请选择一天导出数据",
            });
          }
        } else {
          this.$message({
            type: "error",
            message: "请选择时间",
          });
          return;
        }
      } else {
        // this.exportOut("#tableBox1");
        if (this.type2 == "") {
          this.$message({
            type: "error",
            message: "请选择条件",
          });
          return;
        }
        if (this.date1.length > 0) {
          day1 = new Date(this.date1[0]);
          day2 = new Date(this.date1[1]);
          if (day1.getTime() == day2.getTime()) {
            var dom = document.createElement("a");
            //下载文件的路径
            dom.href =
              this.serveurl.mallserve +
              "/api/admin/ProcessOrderEx/export_auction_refund_data?start=" +
              this.date1[0] +
              "&end=" +
              this.date1[1] +
              "&isRefund=" +
              this.type2;
            //对下载的文件命名
            dom.download = '';
            dom.click();
            this.loaddata = false;
          } else {
            this.$message({
              type: "error",
              message: "请选择一天导出数据",
            });
          }
        } else {
          this.$message({
            type: "error",
            message: "请选择时间",
          });
          return;
        }
      }
    },
    //下载表格
    exportOut(id) {
      var xlsxParam = { raw: true }; //转换成excel时，使用原始的格式
      var wb = XLSX.utils.table_to_book(document.querySelector(id), xlsxParam);
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          new Date().getTime() + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
  },
};
</script>
<style>
.add {
  width: 100%;
  height: 50px;
  text-align: left;
}
.exportmalltab .el-tabs__nav-scroll{
  background-color: #fff;
}
</style>
